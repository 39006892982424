import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: ['Manrope', 'Inter', 'Roboto'].join(','),
  },
  palette: {
    primary: { main: 'rgba(168, 108, 198, 1)', light: 'rgba(165, 118, 188, 1)' },
    secondary: { main: '#F7E38D' },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          color: '#FFF',
          textAlign: 'center',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '21px',
          backgroundColor: 'rgba(168, 108, 198, 1)',
          border: 'none',
          display: 'flex',
          padding: '12px 20px',
          borderRadius: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          width: 'auto',
          '&:hover': {
            border: 'none',
            background: 'rgba(168, 108, 198, 1)',
            color: '#FFF',
          },
          '&:active': {
            border: 'none',
            color: '#FFF',
            background: 'rgba(168, 108, 198, 1)',
          },
          '&:hover:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            margin: '-3px',
            borderRadius: 'inherit',
            background: 'rgb(168, 108, 198 , 0.1)',
          },
        },
        containedPrimary: {
          color: '#FFF',
          textAlign: 'center',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '21px',
          backgroundColor: 'rgba(168, 108, 198, 1)',
          border: 'none',
          display: 'flex',
          padding: '12px 24px',
          borderRadius: '32px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0px',
          width: 'auto',
          '&:hover': {
            border: 'none',
            background: 'rgba(168, 108, 198, 1)',
            color: '#FFF',
          },
          '&:active': {
            border: 'none',
            color: '#FFF',
            background: 'rgba(168, 108, 198, 1)',
          },
          '&:hover:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            margin: '-3px',
            borderRadius: 'inherit',
            background: 'rgb(80, 43, 99 , 0.3)',
          },
        },
        containedSecondary: {
          color: 'rgba(255, 255, 255, 1)',
          background: 'rgba(221, 110, 63, 1)',
          backdropFilter: 'blur(25px)',
          fontWeight: 700,
          borderRadius: '67px',
          letterSpacing: '1px',
          lineHeight: '17px',
          gap: '3px',
          padding: '16px, 83px',
          fontSize: '12px',
          '&:hover': {
            border: 'none',
            background: 'rgba(221, 110, 63, 1)',
            color: 'rgba(255, 255, 255, 1)',
          },
          '&:active': {
            border: 'none',
            color: 'rgba(255, 255, 255, 1)',
            background: 'rgba(221, 110, 63, 1)',
          },
          '&:hover:before': {
            display: 'none',
          },
        },
        outlinedSecondary: {
          color: 'rgba(30, 30, 30, 1)',
          background: '#F7E38D',
          backdropFilter: 'blur(25px)',
          fontWeight: 700,
          borderRadius: '67px',
          letterSpacing: '1px',
          lineHeight: '17px',
          gap: '3px',
          padding: '16px, 83px',
          fontSize: '12px',
          border: '2px solid rgba(255, 255, 255, 1)',
          '&:hover': {
            background: '#F7E38D',
            color: 'rgba(30, 30, 30, 1)',
          },
          '&:active': {
            color: 'rgba(30, 30, 30, 1)',
            background: '#F7E38D',
          },
          '&:hover:before': {
            display: 'none',
          },
        },
        textPrimary: {
          color: 'rgba(165, 118, 188, 1)',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          lineHeight: '17px',
          fontSize: '14px',
          fontWeight: 500,
          background: 'transparent',
          height: 'auto',
          padding: '0px 16px',
          marginBlock: '8px',
          textTransform: 'none',
          '&:hover': {
            background: 'transparent',
            color: 'rgba(165, 118, 188, 1)',
          },
          '&:before': {
            display: 'none',
          },
        },
        textSecondary: {
          color: 'rgba(0, 0, 0, 1)',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          lineHeight: '17px',
          fontSize: '16px',
          fontWeight: 600,
          background: 'transparent',
          height: 'auto',
          textTransform: 'none',
          '&:hover': {
            background: 'transparent',
            color: 'rgba(0, 0, 0, 1)',
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    //   MuiTab: {
    //     styleOverrides: {
    //       root: {
    //         "&.Mui-selected": {
    //           color: "#525FE1",
    //           fontWeight: "700",
    //         },
    //         "&.MuiStepIcon-active": { backgoundColor: "red" },
    //         "&.MuiStepIcon-completed": { backgoundColor: "green" },
    //         "&.Mui-disabled .MuiStepIcon-root": { backgoundColor: "cyan" },
    //       },
    //     },
    //   },
  },
});
